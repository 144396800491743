import axios from 'axios'
import {axiosInstance, BASE_URL} from 'src/core/api'
import {transformPostTokenResponse} from './auth.datatransformer'
import type {PostTokenResponse} from './auth.model'

class AuthService {
  async postToken(email: string, password: string): Promise<PostTokenResponse> {
    const response = await axiosInstance.post(`/api/token/`, {
      email: email,
      password: password,
    })
    return transformPostTokenResponse(response.data)
  }

  async postRefreshToken(refreshToken: string) {
    // Notice that this is the global axios instance, not the axiosInstance!
    const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
      refresh: refreshToken,
    })
    return response.data.access
  }

  async postLogout(refreshToken: string) {
    // Notice that this is the global axios instance, not the axiosInstance!
    const response = await axios.post(`${BASE_URL}/api/token/logout/`, {
      refresh: refreshToken,
    })
    return response.data.access
  }
}

export default new AuthService()
