<script lang="ts" setup>
  import {ELabel, ESelect} from '@elyn-git/magic-system'

  defineProps<{
    tenantOptions: {value: string; key: string}[]
    selectedTenant?: {id: number; name: string}
  }>()

  const emit = defineEmits<{
    (e: 'onTenantSelected', tenantId: number): void
  }>()
</script>
<template>
  <ESelect
    class="w-full"
    :model-value="selectedTenant?.id.toString()"
    placeholder="Choisir un marchand"
    :options="
      tenantOptions.map(x => ({
        label: x.key,
        value: x.value,
      }))
    "
    :has-search="true"
    @update:model-value="
      $event
        ? emit('onTenantSelected', Number.parseInt($event.toString()))
        : null
    "
  >
    <template #label>
      <ELabel class="text-white"> Marchand </ELabel>
    </template>
  </ESelect>
</template>
