<script lang="ts" setup>
  import {RouterView, useRoute} from 'vue-router'
  import NavPanel from './components/v1/navigation/NavPanel.vue'

  import {storeToRefs} from 'pinia'
  import {ref, watch} from 'vue'
  import DevBanner from './components/v1/misc/DevBanner.vue'
  import type {Tenant} from './core/tenants/tenants.model'
  import tenantsService from './core/tenants/tenants.service'
  import {DevEnvManager} from './dev-env-manager'
  import router, {ordersPath, pathNames} from './router'
  import {useStore} from './store'

  const isProdEnv = import.meta.env.VITE_ENVIRONMENT === 'production'
  const localizeKey = import.meta.env.VITE_LOCALIZE_KEY

  const {tenant, userId, isUserLogged} = storeToRefs(useStore())
  const currentRoute = useRoute()
  const tenants = ref<Tenant[]>([])

  if (!isProdEnv) {
    DevEnvManager.init()
  }

  if (localizeKey) {
    // eslint-disable-next-line no-undef
    Localize.initialize({
      key: localizeKey,
      rememberLanguage: true,
      retranslateOnNewPhrases: true,
    })
  }

  const changeBaseUrl = (newUrl: string) => {
    if (isProdEnv) return
    DevEnvManager.changeBaseUrl(newUrl)
  }

  const fetchTenants = async () => {
    tenants.value = await tenantsService.getTenants(+userId.value)
  }

  const onTenantSelected = (tenantId: number | null) => {
    if (!tenantId) tenantId = 0
    if (tenantId === tenant.value?.id) return
    tenant.value = tenants.value.find(t => t.id === tenantId)
    if (currentRoute.name === pathNames.order) {
      const newRoute = ordersPath(tenantId.toString())
      router.push(newRoute)
    } else {
      const newRoute = {
        ...router.currentRoute.value,
        params: {tenantId: tenantId.toString()},
      }
      router.push(newRoute)
    }
  }

  watch(
    isUserLogged,
    value => {
      if (!value) return
      fetchTenants()
    },
    {deep: true},
  )

  watch(
    tenant,
    newTenant => {
      if (
        newTenant &&
        !newTenant.retoolAccessToken &&
        (currentRoute.name === pathNames.analyticsReturns ||
          currentRoute.name === pathNames.analyticsRevenues)
      ) {
        router.push(ordersPath(newTenant.id.toString()))
      }
    },
    {deep: true},
  )
</script>

<template>
  <DevBanner
    v-if="!isProdEnv"
    @change-base-url="changeBaseUrl($event)"
  />
  <div class="flex justify-start gap-4 w-screen h-full">
    <div v-if="isUserLogged">
      <NavPanel
        :tenants="tenants"
        :selected-tenant="tenant"
        @on-tenant-selected="onTenantSelected"
      />
    </div>
    <div class="bg-white overflow-hidden flex-1">
      <div
        :class="{
          'w-screen': !isUserLogged,
        }"
        class="h-full"
      >
        <RouterView />
      </div>
    </div>
  </div>
</template>
