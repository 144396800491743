import axios from 'axios'
import {
  applyAuthTokenInterceptor,
  clearAuthTokens,
  getRefreshToken,
} from 'axios-jwt'
import {DevEnvManager} from 'src/dev-env-manager'
import router, {paths} from 'src/router'
import authService from './auth/auth.service'
import {toastService} from 'src/utils/toast-service'
import {ToastMessageEnum, ToastTypeEnum} from 'src/enums'

const isHttpSecured = !import.meta.env.VITE_ELYN_HOST.includes('localhost')
const protocol = isHttpSecured ? 'https' : 'http'

export let BASE_URL =
  protocol + '://dashboard.' + import.meta.env.VITE_ELYN_HOST
// FIXME: It will be the final URL used into the MD after Feature Freeze
export let API_BASE_URL = protocol + '://api.' + import.meta.env.VITE_ELYN_HOST

if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
  BASE_URL = DevEnvManager.getCurrentBaseUrl()
  API_BASE_URL = BASE_URL.replace('dashboard', 'api')
}

// 1. Create an axios instance that you wish to apply the interceptor to
export const axiosInstance = axios.create({baseURL: BASE_URL})
// Will be used everywhere after endpoints migfration :
export const axiosApiInstance = axios.create({baseURL: API_BASE_URL})

export const requestRefresh = (refreshToken: string) => {
  // Notice that this is the global axios instance, not the axiosInstance!  <-- important
  return authService
    .postRefreshToken(refreshToken)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        clearAuthTokens()
        router.push(paths.login)
      }
    })
}
// 3. Apply interceptor
const handleError = (error: {response: {status: string}}) => {
  if (error.response) {
    console.error('HTTP Error:', error.response.status)
    // This is a V0, it will be improved with a full product feature later.
    if ([500, 400, 404].includes(+error.response.status)) {
      const errorMessageKey = `ERROR_${+error.response.status}`
      const errorMessage =
        ToastMessageEnum[errorMessageKey as keyof typeof ToastMessageEnum]
      toastService.showToast(errorMessage, ToastTypeEnum.ERROR)
    }
  }
  return Promise.resolve({})
}
axiosInstance.interceptors.response.use(response => {
  return response
}, handleError)
axiosApiInstance.interceptors.response.use(response => {
  return response
}, handleError)

applyAuthTokenInterceptor(axiosInstance, {requestRefresh}) // Notice that this uses the axiosInstance instance.  <-- important
applyAuthTokenInterceptor(axiosApiInstance, {requestRefresh}) // Notice that this uses the axiosInstance instance.  <-- important

// 5. Logging out
export const logout = async () => {
  const refreshToken = await getRefreshToken()
  if (refreshToken) {
    return authService.postLogout(refreshToken).then(() => {
      clearAuthTokens()
      router.push(paths.login)
    })
  } else {
    clearAuthTokens()
    router.push(paths.login)
  }
}
