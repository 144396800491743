import {createColumnHelper, type RowData} from '@tanstack/vue-table'
import type {OrderForList} from 'src/core/orders/orders.model'
import {h} from 'vue'
import OrderLink from 'src/components/v1/order-list/OrderLink.vue'
import dayjs from 'dayjs'
import StatusBadge from 'src/components/v1/badges/StatusBadge.vue'
import MaterialsIconVue from '../icons/MaterialsIcon.vue'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    notranslate?: boolean
  }
}

export const columnHelper = createColumnHelper<OrderForList>()

export const pageSizes = [10, 20, 30, 40, 50]

export const sortingFieldMapping = (status: string) => {
  switch (status) {
    case 'customerEmail':
      return 'customer__email'
    case 'customerFullName':
      return 'customer_info__shipping_first_name'
    case 'status':
      return 'latest_status_index'
    case 'comments':
      return 'comments_count'
    default:
      return status
  }
}

export const ordersTableColumns = [
  columnHelper.accessor('displayedOrderId', {
    cell: info =>
      h(
        // @ts-ignore
        OrderLink,
        {
          orderId: info.row.original.id,
          displayedOrderId: info.getValue(),
          orderType: info.row.original.isExchange
            ? 'exchange'
            : info.row.original.orderType,
          hasManualActionControl: !!(
            info.row.original.manualControlAction &&
            !info.row.original.manualControlAction.isFixed
          ),
        },
        () => [],
      ),

    footer: info => info.column.id,
    header: '# Commande',
  }),
  columnHelper.accessor('orderDate', {
    cell: info =>
      info.row.original.orderDate
        ? dayjs(info.row.original.orderDate).format('DD/MM/YYYY')
        : '-',
    footer: info => info.column.id,
    header: 'Date',
    enableSorting: false,
  }),
  columnHelper.accessor('customerFullName', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
    header: 'Client',
    meta: {
      notranslate: true,
    },
  }),
  columnHelper.accessor('customerEmail', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
    header: 'Email client',
    meta: {
      notranslate: true,
    },
  }),

  columnHelper.accessor('lineItemsCount', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
    header: 'nb articles',
  }),

  columnHelper.accessor('commentsCount', {
    cell: info =>
      info.getValue() ? h(MaterialsIconVue, {icon: 'comment'}, []) : null,
    footer: info => info.column.id,
    header: 'Commentaire',
  }),
  columnHelper.accessor('status', {
    // @ts-ignore
    cell: info => h(StatusBadge, {status: info.getValue()}, () => []),
    footer: info => info.column.id,
    header: 'Statut',
  }),
]
