import type {PostTokenResponse} from './auth.model'

export function transformPostTokenResponse(data: {
  access: string
  refresh: string
}): PostTokenResponse {
  return {
    accessToken: data.access,
    refreshToken: data.refresh,
  }
}
