<script lang="ts" setup>
  import type {Tenant} from 'src/core/tenants/tenants.model'
  import router, {
    analysticsRevenuesPath,
    analyticsReturnReasonsPath,
    analyticsReturnsPath,
    customizationPath,
    invoicingPath,
    ordersPath,
    pathNames,
    returnsPath,
    settingsPath,
  } from 'src/router'
  import {computed} from 'vue'
  import TenantPicker from '../admin/TenantPicker.vue'
  import NavDropdown from './NavDropdown.vue'
  import NavLink from './NavLink.vue'
  import TopLogo from './TopLogo.vue'
  import UserBar from './UserBar.vue'
  import {useStore} from 'src/store'
  import {storeToRefs} from 'pinia'

  const props = defineProps<{
    tenants: Tenant[]
    selectedTenant?: Tenant
  }>()
  const {isAdmin} = storeToRefs(useStore())

  const emit = defineEmits<{
    (e: 'onTenantSelected', tenantId: number): void
  }>()

  const goToHelp = () => {
    window.open(
      'https://elyn-io.notion.site/Votre-kit-commer-ant-5fac16b02d7d4357813f1e47533110b5',
      '_blank',
    )
  }

  const tenantOptions = computed(() => {
    return props.tenants
      .map(tenant => ({
        value: tenant.id.toString(),
        key: tenant.name,
      }))
      .sort((a, b) => a.key.localeCompare(b.key))
  })
</script>

<template>
  <div
    class="w-60 h-screen flex flex-col justify-between select-none sticky top-0"
  >
    <TopLogo />
    <div class="flex flex-col justify-between bg-[#2e302f] h-full p-4 gap-2">
      <div class="flex flex-col gap-4">
        <TenantPicker
          v-if="tenants && tenants.length > 1"
          :tenant-options="tenantOptions"
          :selected-tenant="selectedTenant"
          @on-tenant-selected="emit('onTenantSelected', $event)"
        />
        <div v-if="selectedTenant">
          <NavLink
            page-name="Commandes"
            icon="shopping_basket"
            :is-selected="router.currentRoute.value.name === pathNames.orders"
            @click="router.push(ordersPath(selectedTenant.id.toString()))"
          />
          <NavLink
            page-name="Retours"
            icon="swap_horiz"
            :is-selected="router.currentRoute.value.name === pathNames.returns"
            @click="router.push(returnsPath(selectedTenant.id.toString()))"
          />
          <NavDropdown
            v-if="selectedTenant.retoolAccessToken"
            :dropdown-name="'Analytics'"
            :dropdown-icon="'analytics'"
            :links="[
              {
                linkIcon: 'monetization_on',
                linkName: pathNames.analyticsRevenues,
                linkRoute: analysticsRevenuesPath(selectedTenant.id.toString()),
                isSelected:
                  router.currentRoute.value.name ===
                  pathNames.analyticsRevenues,
              },
              {
                linkIcon: 'keyboard_return',
                linkName: pathNames.analyticsReturns,
                linkRoute: analyticsReturnsPath(selectedTenant.id.toString()),
                isSelected:
                  router.currentRoute.value.name === pathNames.analyticsReturns,
              },
              {
                linkIcon: 'psychology_alt',
                linkName: pathNames.analyticsReturnReasons,
                linkRoute: analyticsReturnReasonsPath(
                  selectedTenant.id.toString(),
                ),
                isSelected:
                  router.currentRoute.value.name ===
                  pathNames.analyticsReturnReasons,
              },
            ]"
          />

          <NavLink
            v-if="isAdmin"
            page-name="Personnalisation"
            icon="palette"
            new
            :is-selected="
              router.currentRoute.value.name === pathNames.customization
            "
            @click="
              router.push(customizationPath(selectedTenant.id.toString()))
            "
          />
          <NavLink
            v-if="selectedTenant.retoolAccessToken"
            page-name="Paramètres"
            icon="settings"
            :is-selected="router.currentRoute.value.name === pathNames.settings"
            @click="router.push(settingsPath(selectedTenant.id.toString()))"
          />
          <NavLink
            v-if="selectedTenant.retoolAccessToken"
            page-name="Facturation"
            icon="paid"
            :is-selected="
              router.currentRoute.value.name === pathNames.invoicing
            "
            @click="router.push(invoicingPath(selectedTenant.id.toString()))"
          />
        </div>
      </div>
      <NavLink
        page-name="Help Center"
        icon="help"
        @click="goToHelp"
      />
    </div>
    <UserBar />
  </div>
</template>
