import {axiosApiInstance} from 'src/core/api'
import {transformTenant} from './tenants.datatransformer'
import type {ReturnPolicy, Tenant} from './tenants.model'

class TenantsService {
  async getTenant(tenantId: number): Promise<Tenant> {
    const response = await axiosApiInstance.get(`tenants/${tenantId}`)
    return transformTenant(response.data)
  }

  async getTenants(userId: number): Promise<Tenant[]> {
    const response = await axiosApiInstance.get(`users/${userId}/tenants`)
    if (!response || !response.data) return []
    return response.data.map((tenant: Tenant) => transformTenant(tenant))
  }

  async getReturnPolicies(tenantId: number): Promise<ReturnPolicy[]> {
    const response = await axiosApiInstance.get(
      `tenants/${tenantId}/return-policies`,
    )

    return response.data
  }

  async updateContentJson(
    tenantId: number,
    returnPolicyId: number,
    content: File,
  ): Promise<ReturnPolicy> {
    const response = await axiosApiInstance.postForm(
      `tenants/${tenantId}/return-policies/upload-content/`,
      {
        return_policy_id: returnPolicyId,
        content_file: content,
      },
    )

    return response.data
  }

  async updateCssFile(
    tenantId: number,
    returnPolicyId: number,
    content: File,
  ): Promise<ReturnPolicy> {
    const response = await axiosApiInstance.postForm(
      `tenants/${tenantId}/return-policies/upload-css-file/`,
      {
        return_policy_id: returnPolicyId,
        content_file: content,
      },
    )

    return response.data
  }
}

export default new TenantsService()
