<script lang="ts" setup>
  import {statusMapping as mapping} from 'src/constants'
  import type {OrderStatus} from 'src/enums'
  import {computed, toRefs} from 'vue'

  const props = defineProps<{
    status: OrderStatus
    isHoverable?: boolean
    isExpand?: boolean
  }>()

  const {status, isHoverable, isExpand} = toRefs(props)

  const fixedClasses =
    'rounded-xl select-none text-[9px] uppercase poppins flex hind font-bold h-[20px] items-center justify-center px-2 py-1 text-iconText'
  const classes = computed(() =>
    [
      fixedClasses,
      status.value in mapping
        ? mapping[status.value as keyof typeof mapping].colorClass
        : '',
      status.value in mapping && !!isHoverable.value && isHoverable.value
        ? mapping[status.value as keyof typeof mapping].hoverableColorClass
        : '',
      isExpand?.value ? 'w-full' : 'w-36',
    ].join(' '),
  )
</script>

<template>
  <div :class="classes">
    <span class="leading-none">{{
      status in mapping
        ? mapping[status as keyof typeof mapping].french
        : status
    }}</span>
  </div>
</template>
