import {ToastMessageEnum, ToastTypeEnum} from 'src/enums'
import {toast} from 'vue3-toastify'

class ToasterService {
  config = {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: toast.THEME.DARK,
  }

  showToast(message: ToastMessageEnum, type: ToastTypeEnum) {
    switch (type) {
      case ToastTypeEnum.SUCCESS:
        toast.success(message, this.config)
        break
      case ToastTypeEnum.INFO:
        toast.info(message, this.config)
        break
      case ToastTypeEnum.WARN:
        toast.warn(message, this.config)
        break
      case ToastTypeEnum.ERROR:
        toast.error(message, this.config)
        break
      default:
        console.warn('Type de toast inconnu :', type)
        break
    }
  }
}

export const toastService = new ToasterService()
