export enum UserType {
  Admin = 'admin',
  Merchant = 'merchant',
}

export enum LocalStorageKeys {
  UserType = 'userType',
  UserId = 'userId',
  Tenant = 'tenant',
}
